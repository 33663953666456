import { useContext, useState } from 'react';
import { MainWrapper } from '../../modals/components/layouts/student-dashboard/EditStudentProfile/styles';
import {
  CardHeaderWrapper,
  UserBasicInfoWrapper,
  LikedAvatar,
  NameLocationWrapper,
  Location,
  CardVideoWrapper,
  IconsWrapper,
  IconWrapper,
  Label,
  ButtonsWrapper,
  LikeButton,
  AvatarWrapper,
  NameLink,
  NameNoLink,
  NameLinkBusiness,
  PlayerIconWrapper,
} from './styles';
import {
  BusinessRounded,
  Person,
  StarRounded,
  Favorite,
  ThumbUpRounded,
  Visibility,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import MoreMenu from './MoreMenu/MoreMenu';
import { AuthContext } from '../../lib/context/AuthContext';
import { Button, Tooltip, Typography } from '@mui/material';
import {
  useAddBusinessVideoView,
  useAddCandidateVideoView,
  useAddUserToFavorites,
  useAddVideoLike,
  useDeleteLikedVideo,
  useDeleteUserToFavorites,
} from '../../rq/hooks/useEditProfile.mutation';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import StudentViewBusiness from '../../modals/components/layouts/student-dashboard/StudentViewBusiness/StudentViewBusiness';
import UnsaveProfileModal from './MoreMenu/UnsaveProfileModal';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { PlayerContext } from '../../lib/context/PlayerContext';
import toast from 'react-hot-toast';
import { FlexBox } from '../Flexbox';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import digitalBookBackground from '../../assets/images/digital_book_background.png';

export default function Card({
  profilePicture,
  candidateUserId,
  candidateId,
  businessUserId,
  businessId,
  name,
  lastName,
  country,
  video,
  likes,
  videoViews,
  saves,
  isMyFavorite,
  likedFromMe,
  refetch,
  videoId,
  boughtFromCurrentUser,
  email,
  phoneNumber,
  professions,
  digitalBook,
  downloadCV,
  ...props
}: any) {
  //hooks
  const { t } = useTranslation();
  const { user, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [playedVideo, setPlayedVideo] = useState<boolean>(true);
  const [openUnsaveProfile, setOpenUnsaveProfile] = useState<boolean>(false);
  const [openBusinessModal, setOpenBusinessModal] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);

  // react mutations
  const { mutate: addUserToFavorites } = useAddUserToFavorites({
    onSuccess: () => {
      toast.success('Action was successfull');
      refetch();
    },
  });
  const { mutate: deleteUserToFavorites } = useDeleteUserToFavorites({
    onSuccess: () => {
      toast.success('Action was successfull');
      refetch();
    },
  });

  const { mutate: addLike, isLoading: addVideoLikeLoading } = useAddVideoLike({
    onSuccess: () => {
      toast.success('Action was successfull');
      refetch();
    },
  });

  const { mutate: deleteLike, isLoading: deleteVideoLikeLoading } =
    useDeleteLikedVideo({
      onSuccess: () => {
        toast.success('Action was successfull');
        refetch();
      },
    });

  const { mutate: addCandidateVideoView } = useAddCandidateVideoView({
    onSuccess: () => refetch(),
  });
  const { mutate: addBusinessVideoView } = useAddBusinessVideoView({
    onSuccess: () => refetch(),
  });

  // handlers
  const handleAddUserToFavorites = () => {
    if (isMyFavorite) {
      setOpenUnsaveProfile(true);
    } else {
      addUserToFavorites({ favoriteUserId: candidateUserId });
    }
  };

  const handleLikeUserCard = () => {
    if (likedFromMe) {
      deleteLike({
        likedUserId: candidateUserId ? candidateUserId : businessUserId,
      });
    } else {
      addLike({
        likedUserId: candidateUserId ? candidateUserId : businessUserId,
      });
    }
  };

  const handleAddVideoView = () => {
    if (playedVideo) {
      if (candidateId) {
        addCandidateVideoView({ candidateVideoId: videoId });
      } else {
        addBusinessVideoView({ businessVideoId: videoId });
      }
      setPlayedVideo(false);
    }
  };

  const handleClickProfilePicture = () => {
    if (candidateUserId && user?.businessId) {
      navigate(`/candidate/profile/${candidateId}`);
    } else if (businessId && user?.candidateId) {
      setOpenBusinessModal(true);
    }
  };

  // constants
  const shouldDisplay =
    !user || (!!user?.businessId && !businessUserId) || !!user?.candidateId;

  const showCursor =
    (candidateUserId && user?.businessId) || (businessId && user?.candidateId);
  const { isPlaying, play } = useContext(PlayerContext);

  return (
    <>
      {shouldDisplay && (
        <MainWrapper {...props}>
          <CardHeaderWrapper>
            <FlexBox justifyContent='space-between'>
              <UserBasicInfoWrapper>
                {profilePicture ? (
                  <LikedAvatar
                    isCandidate={candidateUserId}
                    src={profilePicture}
                    onClick={handleClickProfilePicture}
                    style={{ cursor: showCursor && 'pointer' }}
                  />
                ) : (
                  <AvatarWrapper
                    isCandidate={candidateUserId}
                    onClick={handleClickProfilePicture}
                    style={{ cursor: showCursor && 'pointer' }}
                  >
                    {candidateUserId ? (
                      <Person sx={{ color: 'white' }} />
                    ) : (
                      <BusinessRounded sx={{ color: 'white' }} />
                    )}
                  </AvatarWrapper>
                )}
                <NameLocationWrapper>
                  {user?.businessId && candidateId ? (
                    <NameLink to={`/candidate/profile/${candidateId}`}>
                      {name} {lastName}
                    </NameLink>
                  ) : user?.candidateId && businessId ? (
                    <>
                      <NameLinkBusiness
                        onClick={() => setOpenBusinessModal(true)}
                      >
                        {name} {lastName}
                      </NameLinkBusiness>
                      <StudentViewBusiness
                        open={openBusinessModal}
                        setOpen={setOpenBusinessModal}
                        id={businessUserId}
                      />
                    </>
                  ) : (
                    <NameNoLink>
                      {name} {lastName}
                    </NameNoLink>
                  )}
                  <Location>{country}</Location>
                </NameLocationWrapper>
              </UserBasicInfoWrapper>

              {user?.businessId && candidateUserId && isAuthenticated && (
                <Tooltip
                  placement='top'
                  arrow
                  title={
                    isMyFavorite
                      ? t('card.remove_from_stars')
                      : t('card.add_to_stars')
                  }
                >
                  <StarRounded
                    onClick={handleAddUserToFavorites}
                    fontSize='small'
                    sx={{
                      color: isMyFavorite ? 'black' : '#C5C5C5',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </FlexBox>
            <Typography
              mt={1}
              fontSize='14px'
              fontWeight={500}
              sx={{ color: '#487BD9' }}
            >
              {professions?.map((profession: any) => profession).join(', ')}
            </Typography>
          </CardHeaderWrapper>

          <CardVideoWrapper>
            {video ? (
              <>
                {!showControls && (
                  <PlayerIconWrapper
                    onClick={() => {
                      setShowControls(true);
                      play(videoId);
                    }}
                  >
                    <PlayArrowIcon fontSize='large' sx={{ color: 'white' }} />
                  </PlayerIconWrapper>
                )}
                <ReactPlayer
                  url={video}
                  controls={showControls}
                  width='100%'
                  height='200px'
                  onPause={() => {
                    setShowControls(false);
                  }}
                  playing={showControls && isPlaying(videoId)}
                  onPlay={handleAddVideoView}
                  onEnded={() => {
                    setShowControls(false);
                  }}
                />
              </>
            ) : (
              digitalBook && (
                <a
                  href={digitalBook}
                  target='_blank'
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    border: '1px solid #F4F4F4',
                    borderRadius: '10px',
                    height: '200px',
                    cursor: 'pointer',
                    background: `url(${digitalBookBackground})`,
                    backgroundSize: '100% 200px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  <VisibilityRoundedIcon sx={{ fill: '#72C2CC' }} />
                  <p
                    style={{
                      color: '#72C2CC',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {t('digital_book')}
                  </p>
                </a>
              )
            )}

            <IconsWrapper>
              <IconWrapper>
                <Favorite
                  sx={{
                    fontSize: '12px',
                    color: '#9D9D9D',
                    mr: 0.5,
                  }}
                />
                <Label>
                  {likes} {t('card.likes')}
                </Label>
              </IconWrapper>
              <IconWrapper>
                <Visibility
                  sx={{
                    fontSize: '12px',
                    color: '#9D9D9D',
                    mr: 0.5,
                  }}
                />
                <Label>
                  {videoViews} {t('card.views')}
                </Label>
              </IconWrapper>
              {candidateId && (
                <IconWrapper>
                  <StarRounded
                    sx={{
                      fontSize: '14px',
                      color: '#9D9D9D',
                      mr: 0.5,
                    }}
                  />
                  <Label>
                    {saves} {t('card.saves')}
                  </Label>
                </IconWrapper>
              )}
            </IconsWrapper>
          </CardVideoWrapper>
          {isAuthenticated && (
            <ButtonsWrapper>
              <LikeButton
                loading={addVideoLikeLoading || deleteVideoLikeLoading}
                onClick={handleLikeUserCard}
                isCandidate={!!user?.candidateId}
                likedFromMe={likedFromMe}
              >
                {likedFromMe ? (
                  <>
                    <ThumbUpRounded sx={{ fontSize: '14px', mr: 1 }} />
                    {t('card.like')}
                  </>
                ) : (
                  <>
                    <ThumbUpRounded sx={{ fontSize: '14px', mr: 1 }} />
                    {t('card.like')}
                  </>
                )}
              </LikeButton>
              {!!downloadCV && (
                <Button className='capitalize flex-1 bg-[#EEF3FC] text-[#487BD9] rounded-[10px] mr-1'>
                  <a href={downloadCV} target='_blank'>
                    {t('download_application')}
                  </a>
                </Button>
              )}
              <MoreMenu
                isMyFavorite={isMyFavorite}
                candidateId={candidateId}
                businessId={businessId}
                businessUserId={businessUserId}
                handleAddUserToFavorites={handleAddUserToFavorites}
                boughtFromCurrentUser={boughtFromCurrentUser}
                email={email}
                phoneNumber={phoneNumber}
                downloadCV={downloadCV}
                unSaveProfile={() =>
                  deleteUserToFavorites({ favoriteUserId: candidateUserId })
                }
              />
            </ButtonsWrapper>
          )}
          <UnsaveProfileModal
            open={openUnsaveProfile}
            setOpen={setOpenUnsaveProfile}
            unSaveProfile={() =>
              deleteUserToFavorites({ favoriteUserId: candidateUserId })
            }
          />
        </MainWrapper>
      )}
    </>
  );
}
