import React from 'react';
import BillingHistory from './BillingHistory/BillingHistory';
import SubscriptionInfos from './Plans/SubscriptionInfos';

export default function HistoryOfPackage() {
  return (
    <>
      <SubscriptionInfos />
      <BillingHistory />
    </>
  );
}
