import {
  Avatar,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { CoverContainer, CoverContent, CoverParagraph } from './styles';
import { useTranslation } from 'react-i18next';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Link } from 'react-router-dom';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import { useEffect, useState } from 'react';
import noUser from '../../../assets/images/no_user.png';
import { useGetJobAdsQuery } from '../../../rq/hooks/useJob.mutation';
import { BusinessCenterOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import CircularIndeterminate from '../../../components/CircularIndeterminate/CircularIndeterminate';
import { StateType } from '../../../interfaces/JobOffers.model';

const JobAds = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('auth-token');

  const [checkboxValue, setCheckboxValue] = useState({
    cdd: false,
    internship: false,
    jobStudent: false,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const typeOfEmployments = [
    { id: 1, name: 'cdd', label: t('cdd') },
    { id: 2, name: 'internship', label: t('internship') },
    { id: 3, name: 'jobStudent', label: t('job_student') },
  ];

  const {
    data: jobs = [],
    isLoading: jobsLoading,
    refetch: refetchJobs,
  } = useGetJobAdsQuery(
    {
      employmentTypes: selectedIds,
    },
    { enabled: !!token }
  );

  /**handlers */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setCheckboxValue((prevState) => {
      const newState = {
        ...prevState,
        [name as keyof typeof prevState]: checked, // Ensure name is treated as a valid key
      };

      // Compute selected ids after state is updated
      const selectedIds = Object.keys(newState)
        .filter((key) => newState[key as keyof typeof newState])
        .map((key) => typeOfEmployments.find((item) => item.name === key)?.id)
        .filter((id): id is number => id !== undefined);

      setSelectedIds(selectedIds);

      return newState;
    });
  };

  useEffect(() => {
    if (!!token) {
      refetchJobs();
    }
  }, [selectedIds]);

  return (
    <>
      <CoverContainer>
        <CoverContent>
          <CoverParagraph>{t('find_your_ideal_job')}</CoverParagraph>
        </CoverContent>
      </CoverContainer>
      <Container>
        <div className='flex justify-between items-center'>
          <Typography className='text-[#72C2CC]  text-sm lg:text-xl'>
            {t('current_jobs')}
          </Typography>
          <Typography className='text-[#A7A7A7] text-base'>
            {jobs.length} {t('results')}
          </Typography>
        </div>
        <div className='grid  lg:grid-cols-4 gap-2'>
          <div className='p-4 bg-[#F9F9F9] rounded-xl'>
            <Typography className='font-bold'>
              {t('type_of_employment')}
            </Typography>
            <FormGroup className='ml-3'>
              {typeOfEmployments.map(({ id, label, name }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxValue[name as keyof StateType]}
                      onChange={handleChange}
                      name={name}
                      value={id}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </div>
          {!token ? (
            <div className='flex flex-col justify-center items-center w-full col-span-3'>
              <img src={noUser} />
              <Typography className='font-bold text-sm lg:text-base'>
                {t('registered_users_only')}
              </Typography>
              <Typography className='text-[#A7A7A7] text-center text-sm lg:text-base'>
                {t('registered_users_only_desc')}
              </Typography>
            </div>
          ) : (
            <div className='lg:col-span-3 w-full'>
              {jobsLoading ? (
                <CircularIndeterminate />
              ) : (
                <div className='w-full grid lg:grid-cols-2 gap-2'>
                  {jobs.map(
                    ({
                      businessLogoPath,
                      businessName,
                      title,
                      publishDate,
                      employmentTypes,
                      id,
                    }) => (
                      <Link
                        to={`/job-offer/${id}`}
                        className='rounded-xl w-full p-2 border-2 border-solid border-[#ECECEC]'
                        key={id}
                      >
                        <div className='flex items-center border-b-2 border-b-[#EFEBFF] border-solid my-1 py-1'>
                          <div className='flex gap-1'>
                            {businessLogoPath ? (
                              <Avatar
                                src={businessLogoPath}
                                variant='rounded'
                              />
                            ) : (
                              <Avatar src='' variant='rounded'>
                                <BusinessCenterOutlined />
                              </Avatar>
                            )}
                            <div className='flex flex-col'>
                              <Typography className='text-[#9D9D9D] text-sm'>
                                {businessName}
                              </Typography>
                              <Typography className='font-bold text-sm'>
                                {title}
                              </Typography>
                              <div className='flex items-center gap-1'>
                                <CalendarTodayOutlinedIcon className='fill-[#C6C6C6] text-sm' />
                                <Typography className='text-[#4A4D4B] text-sm'>
                                  {t('published_on')}{' '}
                                  {dayjs(publishDate).format('DD/MM/YYYY')}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-1'>
                          <Typography className='text-[#4A4D4B] text-sm flex-1 w-full '>
                            {employmentTypes?.join(', ')}
                          </Typography>
                          <Link
                            to={`/job-offer/${id}`}
                            className='flex items-center gap-1'
                          >
                            <Typography className='text-sm text-[#3F6FE8] underline'>
                              {t('more_details')}
                            </Typography>
                            <CallMadeOutlinedIcon className='text-sm fill-[#3F6FE8]' />
                          </Link>
                        </div>
                      </Link>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default JobAds;
