import * as React from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonUnclock, CreditsTitle, DialogWrapper, Icon } from './styles';
import icon from '../../../../../../assets/icons/my_first_job_icon_black.png';
import { useTranslation } from 'react-i18next';
import { useUnlockCandidateFields } from '../../../../../../rq/hooks/useEditProfile.mutation';
import { useQueryClient } from '@tanstack/react-query';
import { useMyBusinessProfileSideBarQuery } from '../../../../../../rq/hooks/useQueries';
import { useNavigate } from 'react-router-dom';

interface UnlockFieldModalProps {
  openUnlockModal: boolean;
  setOpenUnlockModal: (openUnlockModal: boolean) => void;
  candidateId: number;
  candidateUserId: string;
}

export default function UnlockFieldModal({
  openUnlockModal,
  setOpenUnlockModal,
  candidateUserId,
}: UnlockFieldModalProps) {
  const handleClose = () => {
    setOpenUnlockModal(false);
  };
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data } = useMyBusinessProfileSideBarQuery();
  const navigate = useNavigate();

  const { mutate: unlockField, isLoading } = useUnlockCandidateFields({
    onSuccess: () => {
      queryClient.fetchQuery(['candidate-profile']);
      handleClose();
    },
  });
  const handleUnlock = () => {
    if (data?.credits === 0) {
      navigate('/credits-purchase');
    } else {
      unlockField({ candidateId: candidateUserId });
    }
  };
  return (
    <DialogWrapper
      fullWidth
      aria-labelledby='customized-dialog-title'
      open={openUnlockModal}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          right: 8,
          top: 8,
          alignSelf: 'end',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Icon src={icon} />
        <CreditsTitle>{t('credits.unlock')}</CreditsTitle>
      </DialogContent>
      <DialogActions sx={{ alignSelf: 'center' }}>
        <ButtonUnclock autoFocus loading={isLoading} onClick={handleUnlock}>
          {t('credits.unlock_infos')}
        </ButtonUnclock>
      </DialogActions>
    </DialogWrapper>
  );
}
