import React from 'react';
import { useGetBusinessJobAdCanidatesQuery } from '../../../../../rq/hooks/useJob.mutation';
import { useParams } from 'react-router-dom';
import Card from '../../../../../components/Card/Card';
import { Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainWrapper } from '../../styles';
import { BusinessRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

const JobAdCanidates = () => {
  /**
   * hooks
   */
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: { applicants = [], job } = {}, refetch: refetchApplicants } =
    useGetBusinessJobAdCanidatesQuery(Number(id));

  return (
    <div>
      <Typography className='font-bold text-base'>
        {t('list_of_candidates')} ({applicants.length})
      </Typography>
      <MainWrapper>
        <div className='flex lg:flex-row flex-col justify-between items-start'>
          <div className='flex gap-1'>
            {job?.businessLogoPath ? (
              <Avatar src={job?.businessLogoPath} variant='rounded' />
            ) : (
              <Avatar variant='rounded'>
                <BusinessRounded />
              </Avatar>
            )}
            <div className='flex flex-col '>
              <Typography className='text-sm'>{job?.businessName}</Typography>
              <Typography className='text-sm font-bold'>
                {job?.title}
              </Typography>
              <div className='flex gap-2'>
                <div className='flex items-center gap-1'>
                  <FmdGoodOutlinedIcon className='fill-[#C6C6C6] text-sm' />
                  <Typography className='text-[#4A4D4B] text-sm'>
                    {job?.businessAddress}
                  </Typography>
                </div>
                <div className='flex items-center gap-1'>
                  <CalendarTodayOutlinedIcon className='fill-[#C6C6C6] text-sm' />
                  <Typography className='text-[#4A4D4B] text-sm'>
                    {t('published_on')}{' '}
                    {dayjs(job?.publishDate).format('DD/MM/YYYY')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap-1 items-baseline'>
            <Typography className='border-2 border-solid border-[#72CC8B] text-center rounded-full text-sm px-2'>
              {job?.isBlocked ? t('blocked') : t('active')}
            </Typography>
            {job?.isActive && job?.activeDaysLeft && (
              <Typography className='border-2 border-solid border-[#EDEDED] text-center rounded-full text-sm px-2'>
                {job?.activeDaysLeft} {t('days_left')}
              </Typography>
            )}
          </div>
        </div>
      </MainWrapper>

      {applicants.map((card, i) => (
        <Card
          key={i}
          profilePicture={card.profilePicture}
          candidateUserId={card.candidateUserId}
          candidateId={card.candidateId}
          professions={card.profession}
          name={card.name}
          lastName={card.lastName}
          country={card.country}
          video={card.video}
          videoViews={card.videoViews}
          likes={card.likes}
          saves={card.saves}
          isMyFavorite={card.isMyFavorite}
          likedFromMe={card.likedFromMe}
          videoId={card.videoId}
          boughtFromCurrentUser={card.boughtFromCurrentUser}
          digitalBook={card.digitalBook}
          downloadCV={card.cvPath}
          refetch={refetchApplicants}
          style={{ border: '1px solid #D0D0D0', borderRadius: '20px' }}
        />
      ))}
    </div>
  );
};

export default JobAdCanidates;
