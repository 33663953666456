import {
  Paper,
  Box,
  Grid,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SignUpTitleWrapper,
  SignUpTypo,
  RegisterButton,
  Label,
} from './styles';
import JobsAds from '../../../../components/sharedComponents/JobsAds/JobsAds';
import ImportantInformation from '../ImportantInformation/ImportantInformation';
import BasicInformation from '../BasicInformation/BasicInformation';
import { UserInputs } from '../../../../interfaces/types/User';
import { useAuthMutation } from '../../../../rq/hooks/useAuth.mutation';
import { UploadCV } from '../UploadCV/UploadCV';
import { UploadImage } from '../UploadImage/UploadImage';
import UploadVideo from '../UploadVideo/UploadVideo';
import { RegisterCandidateFormSchema } from '../../../../validators/Register.validator';
import { useNavigate } from 'react-router-dom';
import { readFromStorage } from '../../../../utils/storage/storage';
import { useTranslation, Trans } from 'react-i18next';
import PrivacyPolicy from '../../../../components/PrivacyPolicy/PrivacyPolicy';
import { UploadDigitalBook } from '../UploadDigitalBook/UploadDigitalBook';
import TypeOfEmployment from '../TypeOfEmployment/TypeOfEmployment';

export default function RegisterStudent() {
  const { t } = useTranslation();

  const methods = useForm<UserInputs>({
    reValidateMode: 'onChange',
    defaultValues: { employementTypeIds: [] },
    resolver: yupResolver(RegisterCandidateFormSchema(t)),
  });

  // destructures
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  // react-mutations
  const { mutate: registerMutation, isLoading } = useAuthMutation();
  const lng = readFromStorage('i18nextLng');

  // handlers
  const onSubmit = async (data: UserInputs) => {
    const formData: any = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('address', data.address);
    formData.append('phoneNumber', data.phoneNumber);
    if (data.postalCode) formData.append('postalCode', data.postalCode);

    if (data.CandidateSelectedEducations)
      formData.append(
        'CandidateSelectedEducations',
        JSON.stringify(
          data.CandidateSelectedEducations.map((cse) => {
            return {
              educationId: cse.educationId,
              fieldOfStudyId: cse.fieldOfStudyId,
            };
          })
        )
      );
    if (data.CandidateSelectedLanguages)
      formData.append(
        'CandidateSelectedLanguages',
        JSON.stringify(
          data.CandidateSelectedLanguages.map((cse) => {
            return {
              languageId: cse.languageId,
              languageSkillLevelId: cse.languageSkillLevelId,
            };
          })
        )
      );
    if (data.professions)
      formData.append('professions', JSON.stringify(data.professions));
    if (data.profilePicture)
      formData.append('profilePicture', data.profilePicture);
    if (data.cv?.[0]) formData.append('cv', data.cv?.[0]);
    if (data.video?.[0]) formData.append('video', data.video?.[0]);
    if (data.digitalBook?.[0])
      formData.append('digitalBook', data.digitalBook?.[0]);
    formData.append(
      'employementTypeIds',
      JSON.stringify(data.employementTypeIds)
    );

    if (lng === 'en') {
      formData.append('languageId', 1);
    } else {
      formData.append('languageId', 2);
    }

    registerMutation(formData, {
      onSuccess: () => {
        navigate(`/confirm-email?email=${encodeURIComponent(data.email)}`);
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <JobsAds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <SignUpTitleWrapper>
                  <SignUpTypo variant='h5'>
                    {t('auth.sign_up.title')}
                  </SignUpTypo>
                  <Typography component='p' className='font-montserrat'>
                    {t('auth.sign_up.description')}
                  </Typography>
                </SignUpTitleWrapper>
                <UploadImage />
                <BasicInformation />
                <ImportantInformation />
                <TypeOfEmployment />
                <UploadCV />
                <UploadVideo />
                <UploadDigitalBook />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<Label>{t('auth.sign_up.recieve_news')}</Label>}
                    {...register('receiveNews')}
                  />
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={
                        <Label>
                          <Trans
                            i18nKey='auth.sign_up.agree_terms'
                            values={{
                              terms: `<terms>${t(
                                'auth.sign_up.terms'
                              )}</terms>`,
                              policy: `<a>${t(
                                'auth.sign_up.privacy_policy'
                              )}</a>`,
                            }}
                            components={{
                              a: (
                                <PrivacyPolicy
                                  name={t('auth.sign_up.privacy_policy')}
                                  color='#487bd9'
                                />
                              ),
                              terms: (
                                <PrivacyPolicy
                                  name={t('auth.sign_up.terms')}
                                  color='#487bd9'
                                />
                              ),
                            }}
                          />
                        </Label>
                      }
                      {...register('acceptTerms')}
                    />
                    {!!errors.acceptTerms && (
                      <Typography
                        sx={{ color: 'red' }}
                        variant='caption'
                        display='block'
                      >
                        {errors?.acceptTerms.message}
                      </Typography>
                    )}
                  </FormControl>
                </FormGroup>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <RegisterButton
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                    >
                      {t('auth.sign_up.create_account_button')}
                    </RegisterButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
